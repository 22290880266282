import { animate, query, style, transition, trigger } from '@angular/animations';
import { Component, AfterContentInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { fader } from './route-animations';
import { LoadingService } from './service/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements AfterContentInit{
  hideSpinner = false;

  constructor(public loadingService: LoadingService){

  }

  ngAfterContentInit() {

  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }
}
