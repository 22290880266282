import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(value: Array<any>, filter: string): Array<any> {
    return value.filter(x => x.filters.split(',').includes(filter));
  }

}
