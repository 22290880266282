import { ifStmt } from '@angular/compiler/src/output/output_ast';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'right'
})
export class RightPipe implements PipeTransform {

  transform(value: Array<any>, right: boolean): Array<any> {

    return value.filter((x: any) => {
      if(x.left == !right){
        return x;
      }
      if(x.top && right){
        return x;
      }
    });
  }

}
