import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'region'
})
export class RegionPipe implements PipeTransform {

  transform(value: Array<any>, filter: string, video: boolean): Array<any> {
    if(!video){
      return value.filter(x => x.language.split(',').includes(filter));
    }else{
      return value.filter(x => x.filters.split(',').includes(filter))
    }
  }

}
