import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  loading: boolean = true;

  constructor() { }

  set Loading(loading: boolean){
    this.loading = loading;
  }

  get Loading(){
    return this.loading;
  }
}
