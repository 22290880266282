import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { LoadingService } from '../service/loading.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  user = {password: ''};

  passwordMatch: boolean = true;

  constructor(private api: ApiService, private router: Router, private loadingService: LoadingService) { }

  ngOnInit(): void {
    this.loadingService.Loading = false;
  }

  async submit(){
    let loggedIn = await this.api.login(this.user.password);
    if(loggedIn){
      this.loadingService.Loading = true;
      this.router.navigate(['dashboard']);
    }else{
      this.passwordMatch = false;
    }
  }

  public onKeydown(event) {
    this.submit();
  }
}
