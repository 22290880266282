import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HotspotComponent } from './hotspot/hotspot.component';
import { InfocardComponent } from './infocard/infocard.component';
import { EmbeddingPipe } from './embedding.pipe';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { LandingComponent } from './landing/landing.component';
import { DrilldownComponent } from './drilldown/drilldown.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { ErrorComponent } from './error/error.component';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccessoriesComponent } from './accessories/accessories.component';
import { AccessoryPipe } from './pipe/accessory.pipe';
import { FilterPipe } from './pipe/filter.pipe';
import { RightPipe } from './pipe/right.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './login/login.component';
import { RegionPipe } from './region.pipe';
import { LoadingComponent } from './loading/loading.component';
import { HotspotMenuComponent } from './hotspot-menu/hotspot-menu.component';
import { MenuPipe } from './pipe/menu.pipe';


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HotspotComponent,
    InfocardComponent,
    EmbeddingPipe,
    VideoPlayerComponent,
    LandingComponent,
    DrilldownComponent,
    ErrorComponent,
    AccessoriesComponent,
    AccessoryPipe,
    FilterPipe,
    RightPipe,
    LoginComponent,
    RegionPipe,
    LoadingComponent,
    HotspotMenuComponent,
    MenuPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    RouterModule,
    BrowserAnimationsModule,
    NgbModule
  ],
  providers: [RegionPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
