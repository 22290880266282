import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accessory'
})
export class AccessoryPipe implements PipeTransform {

  transform(value: Array<any>, accessory: boolean): Array<any> {
    if(accessory){
      return value.filter(x => x.filters.split(',').includes("accessories"));
    }
    return value.filter(x => !x.filters.split(',').includes("accessories"));
  }

}
