import { Component, Input, OnInit } from '@angular/core';
import { DashboardComponent } from '../dashboard/dashboard.component';

@Component({
  selector: 'app-hotspot-menu',
  templateUrl: './hotspot-menu.component.html',
  styleUrls: ['./hotspot-menu.component.scss']
})
export class HotspotMenuComponent implements OnInit {

  @Input() dataIn: any;

  hotspots = [];

  constructor(private dashboard: DashboardComponent) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.hotspots = this.dataIn;
    }, 1000)
  }


  boxDisplayCtrl(pin){
    this.dashboard.boxDisplayCtrl(pin);
  }
}
